<template>
    <div class="init">
        <el-form :model="fromValue" label-width="100px" class="elForm" :rules="rules" ref='form_' label-position="top">
                <el-form-item :label="$t('inface.text3')" prop="tenantId">
                    <el-col :span="8">
                        <el-select v-model="fromValue.tenantId" filterable :placeholder="$t('inputTit.xuanze')" :teleported='false'>
                            <el-option v-for="item in shopList" :key="item.id"
                            :label="item.name"
                            :value="item.id" />
                        </el-select>
                    </el-col>
                </el-form-item>
                <el-form-item :label="$t('inface.text4')" prop="appName">
                    <el-col :span="8">
                        <el-input v-model="fromValue.appName" />
                    </el-col>
                </el-form-item>
                <el-form-item :label="$t('inface.text5')" prop="appKey">
                    <el-col :span="10">
                        <el-input v-model="fromValue.appKey" disabled/>
                    </el-col>
                    <el-col :span="10" style="margin-left:20px">
                        <el-input v-model="fromValue.appSecret" disabled/>
                    </el-col>
                    <el-col :span="2">
                        <el-button text type="primary" size="small" @click="bindSecret" >{{$t('inface.text6')}} </el-button>
                    </el-col>
                </el-form-item>
                <el-form-item :label="$t('inface.text7')" prop="rechargeCallbackUrl">
                    <el-col :span="12">
                        <el-input v-model="fromValue.rechargeCallbackUrl" />
                    </el-col>
                </el-form-item>
                <el-form-item :label="$t('inface.text8')" prop="withdrawCallbackUrl">
                    <el-col :span="12">
                        <el-input v-model="fromValue.withdrawCallbackUrl" />
                    </el-col>
                </el-form-item>
                <el-form-item :label="$t('inface.text9')" prop="nftDeployCallbackUrl">
                    <el-col :span="12">
                        <el-input v-model="fromValue.nftDeployCallbackUrl" />
                    </el-col>
                </el-form-item>
                <el-form-item :label="$t('inface.text10')" prop="nftMintCallbackUrl">
                    <el-col :span="12">
                        <el-input v-model="fromValue.nftMintCallbackUrl" />
                    </el-col>
                </el-form-item>
                <el-form-item :label="$t('inface.text11')" prop="nftRechargeCallbackUrl">
                    <el-col :span="12">
                        <el-input v-model="fromValue.nftRechargeCallbackUrl" />
                    </el-col>
                </el-form-item>
                <el-form-item :label="$t('inface.text12')" prop="nftWithdrawCallbackUrl">
                    <el-col :span="12">
                        <el-input v-model="fromValue.nftWithdrawCallbackUrl" />
                    </el-col>
                </el-form-item>
                <el-form-item :label="$t('inface.text12_1')" prop="ipWhite">
                    <el-col :span="16">
                        <el-input v-model="fromValue.ipWhite" :placeholder="$t('inface.text22')" type="textarea"/>
                    </el-col>
                </el-form-item>
                <el-form-item :label="$t('inface.text13')" prop="newGoogleKey">
                    <el-col :span="8">
                        <el-input v-model="fromValue.newGoogleKey" />
                    </el-col>
                </el-form-item>
            </el-form>
    </div>
</template>
<script setup>
    import { ref,defineExpose,reactive,defineEmits,getCurrentInstance } from 'vue'
    import { userStore } from '@/store/user'
    // import addrView from './addr.vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    const { $t } = getCurrentInstance().proxy;
    
    const fromValue = reactive({ 
        appId:'',
        tenantId: '',appName:'',appKey:'',appSecret:'',
        rechargeCallbackUrl:'',withdrawCallbackUrl:'',nftDeployCallbackUrl:'',
        nftMintCallbackUrl:'',nftRechargeCallbackUrl:'',nftWithdrawCallbackUrl:'',
        newGoogleKey:'',ipWhite:'',clientSecret:''
    })
    const isPlatform = ref(null);isPlatform.value = userStore().getIsPlatform
    fromValue.tenantId = userStore().getUserInfo[0].tenantId;
    const shopList = ref([])

    const form_ = ref(null)
    const rules = ref(null); rules.value = addRules.mainRules
    const style_ = ref(0)

    const emit = defineEmits(['successFun'])
    const bindSecret = ()=>{
        Api_.createAppKeyAndAppSecret().then(res=>{
            if(res.code === 0){
                fromValue.appKey = res.data.newAppKey
                fromValue.appSecret = res.data.newAppSecret
                fromValue.clientSecret = res.data.newClientSecret
            }else{
                ElNotification({
                    message: res.msg,
                    type: 'error',
                })
            }
        })
    }
    const completeFun = ()=>{
        if (!form_.value) return
        form_.value.validate((e) => {
            if(e){
                if(style_.value === 0){
                    let obj_ = JSON.parse(JSON.stringify(fromValue))
                    delete obj_.configId
                    console.log(fromValue)
                    Api_.addAppinfoList(obj_).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succAdd'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }else{
                    Api_.updateAppinfoList(fromValue).then(res=>{
                        if(res.code === 0){
                            emit('successFun')
                            ElNotification({
                                message: $t('alert.succUpdate'),
                                type: 'success',
                            })
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }
            }
        })
    }
    const init_ = (e,obj,shopList_)=>{//初始化数据
        shopList.value = shopList_
        style_.value = e;
        if(obj){
            for(let index in fromValue){
                fromValue[index] = obj[index]
            }
        }else{
            for(let index in fromValue){
                fromValue[index] = ''
            }
        }
    }
    defineExpose({init_,completeFun})
</script>