
import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
const mainRules = {
    tenantId: [
        { required: true, message:  t("inputTit.xuanze"), trigger: 'blur' }
    ],
    appName: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    platform: [
        { required: true, message:  t("inputTit.xuanze"), trigger: 'blur' }
    ],
    appKey: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    rechargeCallbackUrl: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    withdrawCallbackUrl: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    nftDeployCallbackUrl: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    nftMintCallbackUrl: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    nftRechargeCallbackUrl: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    nftWithdrawCallbackUrl: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
    ],
    newGoogleKey: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' } 
    ],
    ipWhite: [
        { required: true, message:  t("inputTit.shuru"), trigger: 'blur' } 
    ]
}
export default{
    mainRules
}