import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

export const tableOption1 = (dicData)=> {
    return {
    border: true,
    stripe: true,
    menuAlign: 'center',
    searchMenuSpan: 6,
    align: 'center',
    height: 'auto',
    menuWidth: 240,
    viewBtn: false,
    columnBtn: false,
    addBtn: false,
    editBtn: false,
    delBtn: false,
    refreshBtn: false,
    menu:false,
    column: [
        {
        width: '130',
        label: t("inface.text3"),
        prop: 'tenantId',
        span: 24,
        type: 'select',
        dicData: dicData,
        props: {
            label: 'name',
            value: 'id'
        },
    }, 
    {
        width:100,
        label:  t("inface.text4"),
        prop: 'appName',
        span: 12,
        rules: [
            { required: true, message:  t("inputTit.shuru"), trigger: 'blur' }
        ],
        viewDisplay: false
    },  
    {
        width:200,
        label:  t("inface.text7"),
        prop: 'rechargeCallbackUrl',
    },{
        width:210, 
        label:  t("inface.text8"),
        prop: 'withdrawCallbackUrl',
    },{
        width:210,
        label: t("inface.text9"),
        prop: 'nftDeployCallbackUrl',
    },{
        width:200,
        label: t("inface.text10"),
        prop: 'nftMintCallbackUrl',
    },{
        width:300,
        label: t("inface.text12_1"),
        prop: 'ipWhite',
        labelWidth: 140
        // hide: true,
    },{
        width:250,
        label: t("inface.text11"),
        prop: 'nftRechargeCallbackUrl',
        labelWidth: 140
        // hide: true,
    },{
        width:250,
        label: t("inface.text12"),
        prop: 'nftWithdrawCallbackUrl',
        labelWidth: 140
        // hide: true,
    },
    {
        width:170,
        label:  t("inface.text16"),
        prop: 'createTime',
    }, 
    {
        width:170,
        label: t("inface.text19"),
        prop: 'updateTime',
    },{
        label: t("inface.text14"),
        prop: 'status',
        fixed:'right',
    },
    {
        width:100,
        label:  t("inface.text18"),
        prop: 'updateUser',
    },{
        width: 270,
        label: t("button.operation"),
        prop: 'caozuo',
        placeholder: t("inputTit.shuru"),
        fixed:'right',
      }
]
    }
}