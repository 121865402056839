<template>
    <div>
        <fromSearch :object_='object_' @searchFun='getList'/>
        <avue-crud :option="tableOption" :data="page.list" :page="page" :table-loading="listLoading"
            @size-change="sizeChange"
            @current-change="currentChange">
            <template #menu-left>
                <el-button v-if="roleManager_btn_add" class="filter-item"
                    type="primary"   @click="openView(0)">{{$t('button.add')}} </el-button>
            </template>
            <template #caozuo="scope">
                <el-button
                    v-if="sys_user_edit" text type="primary" size="small"  
                    @click="openView(1,scope.row)" >{{$t('button.update')}} </el-button>
                <el-button
                    v-if="sys_user_look" text type="primary" size="small"  
                    @click="bindSecret(scope.row, 'look')" >{{$t('inface.text1')}} </el-button>
                <el-button
                    v-if="sys_user_reset" text type="primary" size="small"  
                    @click="bindSecret(scope.row, 'reset')" >{{$t('inface.text2')}} </el-button>
            </template>
            <template #createTime="scope">
                {{turnTimeFun(scope.row.createTime)}}
            </template>
            <template #updateTime="scope">
                {{turnTimeFun(scope.row.updateTime)}}
            </template>
            <template #status ="scope">
                <el-switch v-model="scope.row.status" :inactive-value='parseInt(valueArray[0].value)' :active-value='parseInt(valueArray[1].value)' @change='enableFun(scope.row)'/>
            </template>
        </avue-crud>
        <el-dialog v-model="dialogVisibleLook" :title="$t('inface.text1')"  width="30%" >
            <div><strong>appKey：</strong>{{lookParm.appKey}} <el-button class="appKey_1"  :data-clipboard-text="lookParm.appKey" text type="primary">复制</el-button></div>
            <div><strong>appSecret：</strong>{{lookParm.appSecret}} <el-button class="appKey_1"  :data-clipboard-text="lookParm.appSecret" text type="primary">复制</el-button></div>
            <div><strong>clientSecret：</strong>{{lookParm.clientSecret}} <el-button class="appKey_1"  :data-clipboard-text="lookParm.clientSecret" text type="primary">复制</el-button></div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisibleLook = false">{{$t('button.closeQ')}}</el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog v-model="dialogFormVisible" :title='isStyleText[isStyle]' width="600">
            <bindView ref='bindView_' @successFun='successFun'/>
            <template #footer>
                <el-button    @click="dialogFormVisible = false">{{$t('button.closeQ')}} </el-button>
                <el-button type="primary"   @click="completeFun">{{$t('button.tijiao')}}</el-button>
            </template>
        </el-dialog>
    </div>
</template>
<script setup>
    import Api_ from '@/api/index'
    import { ref,reactive,getCurrentInstance,nextTick } from 'vue'
    import { tableOption1 } from "@/const/crud/platform/access";
    import { ElNotification,ElMessageBox,ElMessage } from 'element-plus'
    import { turnTimeFun } from '@/utils/util.js'
    import { getDataStyle } from '@/utils/dataBind/parmsView'  
    
    import mixins from '@/views/mixins/page'
    const { $t } = getCurrentInstance().proxy;
    let pageObj = mixins(['getUserappinfoList'])
    const {page,dialogFormVisible,listLoading,sizeChange,currentChange,getList,successFun} = pageObj
    const isStyle = ref(0)
    const isStyleText = [$t("button.add"),$t("button.update")]
    import bindView from './bind.vue'

    import fromSearch from '@/components/fromSearch.vue'
    import { appInface } from '@/const/from/platform/appInface'
    import ClipboardJS from 'clipboard'
    const object_ = ref(appInface);
    
    const tableOption = ref({});

    const roleManager_btn_add = ref(true)
    const sys_user_edit = ref(true)
    const sys_user_look = ref(true)
    const sys_user_reset = ref(true)
    const formEditdata = reactive({
        appId:'',
        tenantId: '',appName:'',appKey:'',appSecret:'',
        rechargeCallbackUrl:'',withdrawCallbackUrl:'',nftDeployCallbackUrl3:'',
        nftMintCallbackUrl3:'',nftRechargeCallbackUrl2:'',nftWithdrawCallbackUrl2:'',
        newGoogleKey:'',ipWhite:''
    })
    const shopList = ref([])
    const lookParm = reactive({
        appKey:'',
        appSecret:'',
        clientSecret:''
    })
    const dialogVisibleLook = ref(false)

    var clipboard = new ClipboardJS('.appKey_1');
    clipboard.on('success', function() {
        ElMessage({
            message: '复制成功',
            type: 'success',
        })
    });
    const bindView_ = ref(null)
    const openView = (num,parm = {})=>{
        isStyle.value = num
        dialogFormVisible.value = true
        nextTick(()=>{
            bindView_.value.init_(num,parm,shopList.value)
        })
    }
    const completeFun = ()=>{
        bindView_.value.completeFun()
    }
    const bindSecret = (e,str)=>{// key secret操作
        if(str === 'reset'){
            ElMessageBox.confirm($t('inface.text2'), $t('tyle_.warn'), {
                confirmButtonText: $t('button.enter'),
                cancelButtonText: $t('button.close'),
                type: 'warning',
            }).then(()=>{
                Api_.createAppKeyAndAppSecret().then(res=>{
                if(res.code === 0){
                    for(let item in formEditdata){
                        formEditdata[item] = e[item]?e[item]:undefined
                    }
                    formEditdata.appKey = res.data.newAppKey
                    formEditdata.appSecret = res.data.newAppSecret
                    lookParm.appKey = e.appKey
                    lookParm.appSecret = e.appSecret
                    lookParm.clientSecret = e.clientSecret
                    listLoading.value = true
                    Api_.updateAppinfoList(formEditdata).then(res=>{
                        listLoading.value = false
                        if(res.code === 0){
                            dialogVisibleLook.value = true
                            ElNotification({
                                message: $t('alert.succSync_'),
                                type: 'success',
                            })
                            getList(1)
                        }else{
                            ElNotification({
                                message: res.msg,
                                type: 'error',
                            })
                        }
                    })
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                }
            })
            })
        }else if(str === 'look'){
            dialogVisibleLook.value = true
            lookParm.appKey = e.appKey
            lookParm.appSecret = e.appSecret
            lookParm.clientSecret = e.clientSecret
        }
    }
    const getShopList = ()=>{//获得商户列表
        Api_.shopList().then(res=>{
            shopList.value = res.data.records
            tableOption.value = tableOption1(res.data.records)
        })
    }
    const valueArray = ref([])
    getDataStyle('enable_status',(e)=>{valueArray.value = e})
    const enableFun = (e)=>{
        if(e.appId){
            listLoading.value = true
            Api_.updateAppinfoList({status:e.status,appId: e.appId}).then(res=>{
                listLoading.value = false
                if(res.code === 0){
                    ElNotification({
                        message: e.status === '1'?$t('alert.succOpen'):$t('alert.succOff'),
                        type: 'success',
                    })
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                }
                getList()
            })
        }
    }
    getShopList()
    getList(1)
</script>
